<template>
  <div class="player-container is-clickable has-text-centered" @click="$emit('click', player.player_id)">
    <div class="player-header">
      <div class="player-number">{{ player.jersey }}</div>
      <div class="player-age">
        <div class="player-age-number">{{ player.dob | toAge }}</div>
        <div class="player-age-years">años</div>
      </div>
    </div>
    <div class="player-picture">
      <img :src="player.photo" :alt="'player'" />
    </div>
    <div class="player-name">{{ player.name }}</div>
    <div class="player-position">{{ objectPositionsNames[player.position_id.toString()] }}</div>
  </div>
</template>

<script>
export default {
  name: 'PlayerItem',
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      objectPositionsNames: {
        '1': 'Portero',
        '2': 'Defensa Central',
        '3': 'Lateral por izquierda',
        '4': 'Lateral por derecha',
        '5': 'Volante por izquierda',
        '6': 'Volante por derecha',
        '7': 'Volante defensivo',
        '8': 'Volante ofensivo',
        '9': 'Delantero',
        '10': 'Desconocida',
      },
    };
  },
};
</script>

<style scoped lang="scss">
.player-container {
  width: 115px;
  margin: 9px;
  padding: 4px;
  border-radius: 6px;
  border: solid 1px #dadada;
  &:hover {
    border: solid 1px #7ed321;
  }
}

.player-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  color: #57595d;
  & > .player-number {
    font-size: 11px;
    font-weight: bold;
  }
  & > .player-age > .player-age-number {
    font-size: 9px;
  }
  & > .player-age > .player-age-years {
    text-transform: uppercase;
    font-size: 6px;
    opacity: 0.8;
    margin-left: 2px;
  }
}

.player-age {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.player-picture {
  border-radius: 50%;
  height: 59px;
  width: 59px;
  margin: -6px auto 2px;
  & > img {
    height: 59px;
    width: 59px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.player-name {
  font-size: 10px;
  color: #3c3c3c;
}

.player-position {
  font-family: Avenir-Regular;
  font-size: 9px;
  color: #57595d;
}
</style>
