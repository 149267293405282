<template>
  <div class="has-text-left container">
    <CentralItemsContainer hint="">
      <PlayerItem v-for="player in arrayPlayers" :key="player.id" :player="player" @click="onPlayerItemClicked" />
    </CentralItemsContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CentralItemsContainer from '@/components/Elements/CentralItemsContainer';
import PlayerItem from '@/components/Elements/PlayerItem';
import axios from 'axios';

export default {
  name: 'Players',
  components: {
    PlayerItem,
    CentralItemsContainer,
  },
  data() {
    return {
      arrayPlayers: [],
    };
  },
  computed: {
    ...mapState('leagueGeneral', ['objectSeasonSelected']),
    teamId() {
      return Number(this.$route.params.teamId) || 0;
    },
  },
  methods: {
    async fillPlayers() {
      try {
        const response = await axios.get(
          `https://pa3kubpuhg.execute-api.us-west-2.amazonaws.com/prod/playersByTeamAndSeason/${this.objectSeasonSelected.id}/${this.$route.params.teamId}`,
        );
        const objectData = response.data[0];
        this.arrayPlayers = objectData.players || [];
      } catch (e) {
        throw new Error(e);
      }
    },
    onPlayerItemClicked(playerId) {
      this.$router.push({
        name: 'playerProfile',
        query: {
          player: playerId,
          season: this.objectSeasonSelected.id,
          isLanding: true,
        },
      });
    },
  },
  created() {
    this.fillPlayers();
  },
  async mounted() {
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>

<style>
.page-title {
  text-transform: uppercase;
}
</style>
